import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    network: undefined,
    walletAddress: undefined,
    walletConnected: false,
    metamaskExists: false,
    bridgedValue: 0,
  },
  mutations: {
    setBridgedValue(state, payload) {
      state.bridgedValue = payload;
    },
    setNetwork(state, payload) {
      state.network = payload;
    },
    setWalletAddress(state, payload) {
      state.walletAddress = payload;
    },
    setWalletConnected(state, payload) {
      state.walletConnected = payload;
    },
    setMetamaskExists(state, payload) {
      state.metamaskExists = payload;
    },
  },
  actions: {},
  modules: {},
});
