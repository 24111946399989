<template>
  <div
    class="alert text-center text-white font-weight-bold border-0"
    :class="`alert-${type}`"
    role="alert"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AlertBox",
  props: {
    type: {
      type: String,
      default: "info",
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  word-break: break-all;
}
</style>