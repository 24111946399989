<template>
  <div class="notifications-container">
    <notification
      v-for="(notification, index) in notifications"
      :key="index"
      :item="notification"
      :index="index"
      v-on:close-notification="handleCloseNotification"
    />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import Notification from "./Notification.vue";
import { v4 as uuidv4 } from "uuid";
export default {
  data() {
    return {
      notifications: [],
    };
  },
  components: {
    Notification,
  },
  mounted() {
    EventBus.$on("add-notification", (notification) => {
      this.notifications.unshift({
        type: notification.type,
        content: notification.content,
        id: uuidv4(),
      });
    });
  },
  methods: {
    handleCloseNotification(id) {
      const index = this.notifications.findIndex((item) => item.id === id);
      this.notifications.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2rem;
  padding-right: 1rem;

  .notification {
    background: linear-gradient(#253444, #253444) padding-box,
      linear-gradient(
          135deg,
          rgba(49, 166, 117, 1) 0,
          #253444 25%,
          #253444 60%,
          rgba(33, 82, 255, 0.1) 75%,
          rgba(33, 82, 255, 1) 100%
        )
        border-box;
    border-radius: 5px;
    border: 2px solid transparent;
    position: relative;
    padding: 1rem;
    font-size: 0.9em;
    color: #fff;
    position: relative;

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent, transparent) padding-box,
        linear-gradient(
            135deg,
            #fff 0,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 80%,
            #fff 100%
          )
          border-box;
      z-index: -1;
      filter: blur(2px);
    }
  }
}
</style>