import { CHAIN_ID } from "./enum";

// chainId: eth chain id, e.g. 0x3
export const chainIdToText = (chainId) => {
  switch (chainId) {
    case CHAIN_ID.MAINNET:
      return "Ethereum Mainnet";
    case CHAIN_ID.ROPSTEN:
      return "Ropsten Testnet";
    case CHAIN_ID.RINKEBY:
      return "Rinkeby Testnet";
    case CHAIN_ID.GOERLI:
      return "Goerli Testnet";
    case CHAIN_ID.KOVAN:
      return "Kovan Testnet";
    default:
      return "Undefined Network";
  }
};

// fetch the rightful set of env variables
export const getEnvConfig = () => {
  const env = window.__env.config.environment;
  return window.__env.config[env];
};

export const getAlchemyAPI = () => {
  const env = window.__env.config.environment;
  switch (env) {
    case "dev":
      return process.env.VUE_APP_ALCHEMY_API_DEV;
    case "stg":
      return process.env.VUE_APP_ALCHEMY_API_STG;
    case "prd":
      return process.env.VUE_APP_ALCHEMY_API_PRD;
    default:
      return process.env.VUE_APP_ALCHEMY_API_DEV;
  }
};
