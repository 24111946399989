<template>
  <div>
    <div class="text-center msg-box" v-if="!metamaskExists">
      MetaMask is required for the interim ERC-20 ZIL token swap.<br />Please
      connect your MetaMask Extension.
    </div>
    <div>
      <div
        class="nav-wrapper position-relative end-0 mb-4"
        v-if="metamaskExists"
      ></div>
      <approve-panel
        :web3="web3"
        :balanceQa="balanceQa.toString()"
        :balance="balance"
        :alreadyApproved="alreadyApproved"
        v-if="tab === 'approve' && !loading"
      />

      <alert-box type="warning" v-if="warning">{{ warning }}</alert-box>
      <alert-box type="danger" v-if="error">{{ error }}</alert-box>
    </div>
  </div>
</template>

<script>
import AlertBox from "./AlertBox.vue";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import { EventBus } from "@/event-bus";
import { getAlchemyAPI, getEnvConfig } from "../utils/utils";
import { CHAIN_ID } from "../utils/enum";
import ApprovePanel from "@/components/ApprovePanel";

const envConfig = getEnvConfig();

export default {
  name: "MainPanel",
  data() {
    return {
      tab: "approve",
      warning: false,
      error: false,
      loading: false,
      success: false,
      balance: 0,
      balanceQa: 0,
      alreadyApproved: false,
      amountDisabled: false,
      amount: 0,
      web3: undefined,
      wrapperApproveTx: undefined,
      wrapperApproveData: undefined,
      amountFinalDisabled: false,
      swapData: undefined,
      amountFinal: 0,
      finalbalance: 0,
    };
  },
  components: {
    AlertBox,
    ApprovePanel,
  },
  computed: {
    metamaskExists() {
      return this.$store.state.metamaskExists;
    },
    walletAddress() {
      return this.$store.state.walletAddress;
    },
  },
  methods: {
    handleSwitchTab(type) {
      this.tab = type;
    },
    async getBalance() {
      const minABI = [
        {
          constant: true,
          inputs: [],
          name: "name",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_spender", type: "address" },
            { name: "_value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "totalSupply",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_from", type: "address" },
            { name: "_to", type: "address" },
            { name: "_value", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "pausedPublic",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "decimals",
          outputs: [{ name: "", type: "uint8" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [{ name: "_value", type: "uint256" }],
          name: "burn",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "pausedOwnerAdmin",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_spender", type: "address" },
            { name: "_subtractedValue", type: "uint256" },
          ],
          name: "decreaseApproval",
          outputs: [{ name: "success", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [{ name: "_owner", type: "address" }],
          name: "balanceOf",
          outputs: [{ name: "balance", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_from", type: "address" },
            { name: "_value", type: "uint256" },
          ],
          name: "burnFrom",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "owner",
          outputs: [{ name: "", type: "address" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [{ name: "newAdmin", type: "address" }],
          name: "changeAdmin",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "symbol",
          outputs: [{ name: "", type: "string" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_to", type: "address" },
            { name: "_value", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ name: "", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "_spender", type: "address" },
            { name: "_addedValue", type: "uint256" },
          ],
          name: "increaseApproval",
          outputs: [{ name: "success", type: "bool" }],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "token", type: "address" },
            { name: "amount", type: "uint256" },
          ],
          name: "emergencyERC20Drain",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [
            { name: "_owner", type: "address" },
            { name: "_spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ name: "", type: "uint256" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          constant: false,
          inputs: [
            { name: "newPausedPublic", type: "bool" },
            { name: "newPausedOwnerAdmin", type: "bool" },
          ],
          name: "pause",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: false,
          inputs: [{ name: "newOwner", type: "address" }],
          name: "transferOwnership",
          outputs: [],
          payable: false,
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          constant: true,
          inputs: [],
          name: "admin",
          outputs: [{ name: "", type: "address" }],
          payable: false,
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { name: "_admin", type: "address" },
            { name: "_totalTokenAmount", type: "uint256" },
          ],
          payable: false,
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "_burner", type: "address" },
            { indexed: false, name: "_value", type: "uint256" },
          ],
          name: "Burn",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "previousAdmin", type: "address" },
            { indexed: true, name: "newAdmin", type: "address" },
          ],
          name: "AdminTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [{ indexed: false, name: "newState", type: "bool" }],
          name: "PausePublic",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [{ indexed: false, name: "newState", type: "bool" }],
          name: "PauseOwnerAdmin",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "previousOwner", type: "address" },
            { indexed: true, name: "newOwner", type: "address" },
          ],
          name: "OwnershipTransferred",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "owner", type: "address" },
            { indexed: true, name: "spender", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            { indexed: true, name: "from", type: "address" },
            { indexed: true, name: "to", type: "address" },
            { indexed: false, name: "value", type: "uint256" },
          ],
          name: "Transfer",
          type: "event",
        },
      ];

      const interimAddress = envConfig["interim_zil"];
      const contract = new this.web3.eth.Contract(minABI, interimAddress);

      const swapAddress = envConfig["swap"];

      contract.methods
        .allowance(this.walletAddress, swapAddress)
        .call()
        .then((result) => {
          if (Number(result) > 0) {
            this.alreadyApproved = true;
          }
        })
        .catch((err) => {
          this.alreadyApproved = false;
          console.error(err);
        });

      contract.methods
        .balanceOf(this.walletAddress)
        .call()
        .then((result) => {
          this.balance = result / Math.pow(10, 12);
          this.balanceQa = result;
        })
        .catch((err) => {
          this.balance = 0;
          console.error(err);
        });

      const bridgedAddress = envConfig["wrapped_zil"];
      const contract2 = new this.web3.eth.Contract(minABI, bridgedAddress);

      contract2.methods
        .balanceOf(this.walletAddress)
        .call()
        .then((result) => {
          this.$store.commit("setBridgedValue", result / Math.pow(10, 12));
        })
        .catch((err) => {
          this.balance = 0;
          console.error(err);
        });
    },
    async initweb3() {
      this.loading = false;
      this.error = false;
      if (window.ethereum) {
        EventBus.$emit("add-notification", {
          type: "default",
          content: "Please confirm on your MetaMask Extension.",
        });
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then(async (accounts) => {
            this.loading = false;
            EventBus.$emit("add-notification", {
              type: "default",
              content: "Wallet successfully connected.",
            });
            this.success = "Wallet successfully connected";
            this.$store.commit("setMetamaskExists", true);
            this.$store.commit("setWalletAddress", accounts[0]);
            this.$store.commit("setNetwork", window.ethereum.chainId);
            this.getBalance();

            setInterval(() => {
              this.getBalance();
            }, 30000);

            // display network warning after connected
            const chainId = window.ethereum.chainId;

            if (
              window.__env.config.environment === "dev" &&
              chainId !== CHAIN_ID.RINKEBY
            ) {
              this.warning = "Please select Rinkeby";
            } else if (
              window.__env.config.environment === "stg" &&
              chainId !== CHAIN_ID.MAINNET
            ) {
              this.warning = "Please select Ethereum Mainnet";
            } else if (
              window.__env.config.environment === "prd" &&
              chainId !== CHAIN_ID.MAINNET
            ) {
              this.warning = "Please select Ethereum Mainnet";
            }
          })
          .catch((reason) => {
            this.loading = false;
            this.warning = false;
            this.error = reason.message;
            console.error(reason);
          });
      } else {
        this.loading = false;
        this.$store.commit("setMetamaskExists", false);
        this.$store.commit("setWalletAddress", undefined);
        this.$store.commit("setNetwork", undefined);
        this.metamaskExists = false;
      }
    },
  },
  mounted() {
    const alchemyAPI = getAlchemyAPI();

    this.web3 = createAlchemyWeb3(alchemyAPI);

    EventBus.$on("initweb3", async () => {
      await this.initweb3();
    });

    EventBus.$on("success-swap", (payload) => {
      this.swapData = payload;
    });

    if (window.ethereum) {
      this.loading = "Please connect your Metamask Account";
      EventBus.$emit("add-notification", {
        type: "default",
        content: "Please connect your Metamask Account.",
      });

      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          this.$store.commit("setMetamaskExists", true);
          this.$store.commit("setWalletAddress", undefined);
          this.getBalance();
        } else {
          this.$store.commit("setMetamaskExists", true);
          this.$store.commit("setWalletAddress", undefined);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        if (chainId) {
          this.$store.commit("setNetwork", chainId);

          if (
            window.__env.config.environment === "dev" &&
            chainId !== CHAIN_ID.RINKEBY
          ) {
            this.warning = "Please select Rinkeby";
          } else if (
            window.__env.config.environment === "stg" &&
            chainId !== CHAIN_ID.MAINNET
          ) {
            this.warning = "Please select Ethereum Mainnet";
          } else if (
            window.__env.config.environment === "prd" &&
            chainId !== CHAIN_ID.MAINNET
          ) {
            this.warning = "Please select Ethereum Mainnet";
          } else {
            this.warning = false;
          }
        } else {
          this.warning = false;
          this.$store.commit("setNetwork", undefined);
        }
      });
    } else {
      this.loading = false;
      this.warning = false;
      this.$store.commit("setMetamaskExists", false);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.msg-box {
  border-radius: 8px;
  color: #fafaff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: linear-gradient(#253444, #253444) padding-box,
    linear-gradient(
        135deg,
        rgba(49, 166, 117, 1) 0,
        #253444 25%,
        #253444 60%,
        rgba(33, 82, 255, 0.1) 75%,
        rgba(33, 82, 255, 1) 100%
      )
      border-box;
}

.nav-link {
  cursor: pointer;
}
</style>
