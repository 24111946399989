<template>
  <section>
    <!-- Navbar -->
    <nav
      class="
        navbar navbar-expand-lg
        position-absolute
        top-0
        z-index-3
        w-100
        shadow-none
        my-3
        navbar-transparent
        mt-4
        mb-5
      "
    >
      <div class="container">
        <button
          class="navbar-toggler shadow-none ms-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon mt-2">
            <span class="navbar-toggler-bar bar1"></span>
            <span class="navbar-toggler-bar bar2"></span>
            <span class="navbar-toggler-bar bar3"></span>
          </span>
        </button>
        <div
          class="collapse navbar-collapse w-100 pt-3 pb-2 py-lg-0"
          id="navigation"
        >
          <ul class="navbar-nav navbar-nav-hover mx-auto"></ul>
          <ul class="navbar-nav d-lg-block d-none">
            <li class="nav-item list-item-padding">
              <button
                class="btn btn-sm btn-border-gradient mb-0 me-1"
              >
                <a class="text-primary" href="https://blog.zilliqa.com/interim-erc-20-zil-token-swap-tutorial-d7ae10c7d4aa" target="_blank">Tutorial</a>
              </button>
            </li>
            <li class="nav-item list-item-padding" v-if="!metamaskExists">
              <button
                @click="initweb3()"
                class="btn btn-sm btn-border-gradient mb-0 me-1"
              >
                Connect
              </button>
            </li>
            <li class="nav-item list-item-padding" v-if="walletAddress">
              <div
                class="
                  btn btn-sm btn-border-gradient
                  mb-0
                  me-1
                  d-flex
                  flex-column
                  align-items-end
                "
              >
                <a
                  class="text-primary"
                  :href="`https://etherscan.io/address/${walletAddress}`"
                  target="_blank"
                  >{{ walletAddress }}</a
                >

                <span class="text-secondary" v-if="walletAddress && network">{{
                  network
                }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
    <div class="container">
      <div class="row align-items-top">
        <div class="col-md-5">
          <h1 class="text-white mb-2">Interim ERC-20 ZIL<br />Token Swap</h1>
          <p class="text-lead text-white mb-2">
            Swap
            <span class="text-info font-weight-bolder">Interim ERC-20 ZIL</span>
            for <span class="text-info font-weight-bolder">Native ZIL</span> on
            <span class="text-primary font-weight-bolder">Zilliqa mainnet</span>
            via a two-step process:
          </p>
          <div class="mb-4">
            <p
              class="mb-0"
              :class="{ 'text-white': !bridged || bridgedValue }"
            >
              1. Swap your interim ERC-20 ZIL for Bridged ERC-20 ZIL
            </p>
            <p :class="{ 'text-white': bridged || bridgedValue > 0 }">
              2. Convert ERC-20 Bridged ZIL to Native ZIL via ZILBridge
            </p>
          </div>
          <div class="text-white" v-if="metamaskExists">
            <div class="mb-4">
              You have
              <span class="text-primary text-bold pr-2">{{ bridgedValue }}&nbsp;</span>
              <span class="text-primary">Bridged ERC-20 ZIL.</span>
              <div v-if="bridgedValue > 0">
                Head over to ZilBridge to convert to native ZIL on Zilliqa
                mainnet.
              </div>
            </div>
            <div v-if="bridgedValue > 0">
              <a
                href="https://zilswap.io/bridge"
                class="btn btn-sm btn-border-gradient"
                >Go to ZilBridge</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-5 offset-md-1">
          <main-panel />
        </div>
      </div>
    </div>
    <notifications />
  </section>
</template>

<script>
import MainPanel from "@/components/MainPanel";
import { EventBus } from "@/event-bus";
import { chainIdToText } from "../utils/utils";
import Notifications from "@/components/Notifications.vue";

export default {
  name: "Home",
  data() {
    return {
      bridged: false,
    };
  },
  components: {
    MainPanel,
    Notifications,
  },
  computed: {
    bridgedValue() {
      return this.$store.state.bridgedValue;
    },
    network() {
      return chainIdToText(this.$store.state.network);
    },
    walletAddress() {
      return this.$store.state.walletAddress;
    },
    metamaskExists() {
      return this.$store.state.metamaskExists;
    },
  },
  methods: {
    initweb3() {
      EventBus.$emit("initweb3");
    },
  },
  mounted() {
    EventBus.$on("success-swap", () => {
      this.bridged = true;
    });
    EventBus.$on("set-bridged-value", (payload) => {
      this.bridged = payload;
    });
  },
};
</script>

<style lang="scss" scoped>
section {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-family: "ClashDisplay-Semibold";
  font-size: 2.75rem;
}

p {
  font-family: "ClashDisplay-Variable";
  font-size: 1.15rem;
}

.list-item-padding {
  padding-left: 25px;
  float: left;
}

.btn {
  font-size: 1em;
  font-weight: 500 !important;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  color: #2dd7a2;
}
</style>
