<template>
  <div>
    <div class="card z-index-0 approve-panel" v-if="metamaskExists">
      <div class="card-body">
        <form role="form text-left text-white">
          <div>
            <div class="row mb-3 align-items-center">
              <div class="col-12 col-md-8">
                <div class="text-white">
                  Your current<br />interim ERC-20 ZIL balance:
                </div>
              </div>
              <div class="col-12 col-md-4 text-white">{{ balance }} ZIL</div>
              <div class="col-12 col-md-7 d-none">
                <div class="text-white font-weight-bold">
                  <div class="input-group">
                    <label class="text-white input-group-text" for="interim">
                      ZIL
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4 d-none">
            <div class="row">
              <div class="col-12 col-md-5"></div>
              <div class="col-12 col-md-7 text-center">
                <img src="@/assets/down-arrow.svg" />
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="wrapperApproveData || alreadyApproved">
            <div class="row align-items-center">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <div class="text-white">
                  Amount to swap for<br />Bridged ERC-20 ZIL:
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="input-group">
                  <input
                    type="text"
                    id="bridged"
                    class="form-control"
                    placeholder="Amount"
                    aria-label="Amount"
                    aria-describedby="email-addon"
                    v-model="amount"
                  />
                  <label class="input-group-text text-white" for="bridged"
                    >ZIL</label
                  >
                </div>
              </div>
              <div class="col-12 mt-2 d-flex justify-content-end">
                <a href="#" class="max-btn" @click="selectMaxValue">Max</a>
              </div>
            </div>
          </div>
          <div class="text-center mb-4">
            <button
              type="button"
              class="btn btn-sm btn-border-gradient mb-0 me-1"
              @click="handleApproveWrapped"
              :disabled="loading === false ? false : true"
              v-if="!wrapperApproveData && !alreadyApproved"
            >
              <div v-if="loading">
                Confirming transaction <i class="fas fa-spin ml-4"></i>
              </div>
              <span v-else>Approve Token Swap</span>
            </button>
          </div>
          <div class="text-center" v-if="wrapperApproveData || alreadyApproved">
            <button
              type="button"
              class="btn btn-sm btn-border-gradient mb-0 me-1"
              @click="handleDoSwap"
              :disabled="loading === false ? false : true"
            >
              <div v-if="loading">
                Confirming transaction <i class="fas fa-spin ml-4"></i>
              </div>
              <span v-else>Swap Tokens</span>
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="error-box text-center mt-3" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import { getEnvConfig } from "../utils/utils";
import { EventBus } from "@/event-bus";

const envConfig = getEnvConfig();

export default {
  components: {},
  name: "ApprovePanel",
  data() {
    return {
      amount: 0,
      wrapperApproveTx: undefined,
      wrapperApproveData: undefined,
      loading: false,
      error: false,
      success: false,
      swapTxData: undefined,
    };
  },
  props: {
    balance: {
      type: Number,
      default: 0,
    },
    balanceQa: {
      type: String,
      default: "0",
    },
    alreadyApproved: {
      type: Boolean,
      default: false,
    },
    web3: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    metamaskExists() {
      return this.$store.state.metamaskExists;
    },
    walletAddress() {
      return this.$store.state.walletAddress;
    },
  },
  methods: {
    selectMaxValue() {
      this.amount = this.balance;
    },
    async wait(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async watchTx(hash, type) {
      this.loading = "Waiting for transaction to be confirmed...";
      EventBus.$emit("add-notification", {
        type: "default",
        content: "Waiting for transaction to be confirmed.",
      });

      const txDetails = await window.ethereum.request({
        method: "eth_getTransactionReceipt",
        params: [hash],
      });

      if (txDetails === null) {
        await this.wait(10000);
        await this.watchTx(hash, type);
      } else {
        this.loading = false;
        this.wrapperApproveTx = txDetails;

        if (type === "swapTx") {
          this.swapTxData = txDetails;
          EventBus.$emit("success-swap", { txDetails, amount: this.amount });
        }

        if (txDetails.status !== "0x1") {
          this.error = "Transaction failed. Please try again.";
          EventBus.$emit("add-notification", {
            type: "default",
            content: "Transaction failed. Please try again.",
          });
        } else {
          this.wrapperApproveData = this.balance;
          EventBus.$emit("add-notification", {
            type: "default",
            content: "Transaction successfully confirmed.",
          });
        }

        return type;
      }
    },
    async handleApproveWrapped() {
      this.loading = "Please confirm on your Metamask Extension.";
      EventBus.$emit("add-notification", {
        type: "default",
        content: "Please confirm on your Metamask Extension.",
      });
      this.success = false;

      const ABI = [
        {
          inputs: [
            {
              internalType: "address",
              name: "_lockProxyAddress",
              type: "address",
            },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "owner",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "spender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Approval",
          type: "event",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "from",
              type: "address",
            },
            {
              indexed: true,
              internalType: "address",
              name: "to",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "value",
              type: "uint256",
            },
          ],
          name: "Transfer",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "owner", type: "address" },
            { internalType: "address", name: "spender", type: "address" },
          ],
          name: "allowance",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "value", type: "uint256" },
          ],
          name: "approve",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "account", type: "address" },
          ],
          name: "balanceOf",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "circulatingSupply",
          outputs: [
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "decimals",
          outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            {
              internalType: "uint256",
              name: "subtractedValue",
              type: "uint256",
            },
          ],
          name: "decreaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "spender", type: "address" },
            { internalType: "uint256", name: "addedValue", type: "uint256" },
          ],
          name: "increaseAllowance",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "lockProxyAddress",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "name",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "symbol",
          outputs: [{ internalType: "string", name: "", type: "string" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "totalSupply",
          outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transfer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            { internalType: "address", name: "sender", type: "address" },
            { internalType: "address", name: "recipient", type: "address" },
            { internalType: "uint256", name: "amount", type: "uint256" },
          ],
          name: "transferFrom",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];

      const interimAddress = envConfig["interim_zil"];
      const swapAddress = envConfig["swap"];

      const contract = new this.web3.eth.Contract(ABI, interimAddress);

      const transactionParameters = {
        to: interimAddress,
        from: this.walletAddress, // must match user's active address.
        data: contract.methods.approve(swapAddress, this.balanceQa).encodeABI(),
      };

      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
        this.success = `Tx sent: ${txHash}`;
        EventBus.$emit("add-notification", { type: "hash", content: txHash });

        await this.watchTx(txHash, "wrapperApproveTx");
      } catch (error) {
        this.error = error.message;
      }
    },

    async handleDoSwap() {
      this.loading = "Please confirm on your Metamask Extension.";
      EventBus.$emit("add-notification", {
        type: "default",
        content: "Please confirm on your Metamask Extension.",
      });
      this.success = false;

      const ABI = [
        {
          inputs: [
            { internalType: "address", name: "address0", type: "address" },
            { internalType: "address", name: "address1", type: "address" },
            { internalType: "address", name: "admin", type: "address" },
            { internalType: "address", name: "initPayer", type: "address" },
          ],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          anonymous: false,
          inputs: [
            {
              indexed: true,
              internalType: "address",
              name: "sender",
              type: "address",
            },
            {
              indexed: false,
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "SwapEvent",
          type: "event",
        },
        {
          inputs: [
            { internalType: "address", name: "newPayer", type: "address" },
          ],
          name: "changePayer",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "payer",
          outputs: [{ internalType: "address", name: "", type: "address" }],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            { internalType: "uint256", name: "_callAmount", type: "uint256" },
          ],
          name: "swap",
          outputs: [{ internalType: "bool", name: "", type: "bool" }],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];

      const swapAddress = envConfig["swap"];

      const contract = new this.web3.eth.Contract(ABI, swapAddress);

      const transactionParameters = {
        to: swapAddress,
        from: this.walletAddress, // must match user's active address.
        data: contract.methods
          .swap(Number(this.amount) * Math.pow(10, 12))
          .encodeABI(),
      };

      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
        this.success = `Tx sent: ${txHash}`;
        EventBus.$emit("add-notification", { type: "hash", content: txHash });

        await this.watchTx(txHash, "swapTx");
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.approve-panel {
  padding: 1rem 1.5rem 0rem 1.5rem;
}

.btn {
  font-size: 1em;
  font-weight: 500 !important;
  padding-top: 0.7em;
  padding-bottom: 0.7em;
  color: #2dd7a2;
}

.error-box {
  font-size: 0.9em;
  border-radius: 8px;
  color: #fafaff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: linear-gradient(#800020, #800020) padding-box;
}

input.form-control {
  border: 0 !important;
  padding: 1rem;
}

.input-group-text {
  border: 0 !important;
  border-left: 4px solid rgba(255, 255, 255, 0.05) !important;
}

.max-btn {
  color: #2dd7a2;
  font-size: 0.9em;
  z-index: 99999;
}
</style>
