<template>
  <div class="notification mb-4">
    <div v-if="item.type === 'hash'" class="ellipsis-flow">
      TX: <a
        class="text-primary"
        target="_blank"
        :href="`https://etherscan.io/tx/${item.content}`"
        >{{ item.content }}</a
      >
    </div>
    <div v-if="item.type === 'default'">{{ item.content }}</div>
    <img
      src="@/assets/close-circle.svg"
      class="close-button"
      @click="handleCloseNotification"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    handleCloseNotification() {
      this.$emit("close-notification", this.item.id);
    },
  },
  mounted() {
    setTimeout(() => {
      this.handleCloseNotification();
    }, 10000);
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-flow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.notifications-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2rem;
  padding-right: 1rem;

  .notification {
    background: linear-gradient(#253444, #253444) padding-box,
      linear-gradient(
          135deg,
          rgba(49, 166, 117, 1) 0,
          #253444 25%,
          #253444 60%,
          rgba(33, 82, 255, 0.1) 75%,
          rgba(33, 82, 255, 1) 100%
        )
        border-box;
    border-radius: 5px;
    border: 2px solid transparent;
    position: relative;
    padding: 1rem;
    font-size: 0.9em;
    color: #fff;
    position: relative;

    .close-button {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(transparent, transparent) padding-box,
        linear-gradient(
            135deg,
            #fff 0,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0) 80%,
            #fff 100%
          )
          border-box;
      z-index: -1;
      filter: blur(2px);
    }
  }
}
</style>