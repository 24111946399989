<template>
    <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
// @import "@/assets/scss/soft-design-system-pro.scss";
html,
body {
  height: 100%;
  background: linear-gradient(112.82deg, #0d1218 1.03%, #10151d 98.54%);
}

body {
  background-image: url("~@/assets/bg-art.png");
  background-size: cover;
  background-repeat: no-repeat;
}
</style>